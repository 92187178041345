import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import {
  colors,
  fonts,
  fontSizer,
  buttonThree,
  B1Black,
  H3Green,
} from "../../styles/helpers"

const settings = {
  slidesToShow: 2,
  slidesToScroll: 1,
  fade: false,
  draggable: true,
  infinite: true,
  speed: 250,
  autoplay: false,
  autoplaySpeed: 15000,
  centerMode: true,
  centerPadding: "200px",
  arrows: true,
  dots: false,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 1,
        centerPadding: "200px",
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        centerPadding: "0px",
      },
    },
  ],
}

const CommunityStoriesSection = styled.section`
  position: relative;
  .communityPattern {
    position: absolute;
    right: 0;
    top: -1rem;
    left: 0;
    width: 100%;
    height: 5rem;
    z-index: -1;
  }

  .wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    padding: 2.25rem 0;
  }

  .googleReviews {
    width: 100%;
    margin-bottom: 4rem;
    text-align: center;
    a {
      ${buttonThree};
    }
  }

  .commSlider {
    position: relative;
    width: 100%;

    .slick-list,
    .slick-track,
    .slick-slide {
      @media (min-width: 768px) {
        display: flex;
      }

      &:focus {
        outline: none !important;
      }
    }

    .slick-slide {
      & > div {
        display: flex;
      }
    }

    @media (min-width: 768px) {
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 20rem;
        background: ${colors.white};
        content: "";
        z-index: 500;
      }
    }

    .slick-arrow {
      display: none;

      @media (min-width: 768px) {
        display: block;
        position: absolute;
        left: 5rem;
        z-index: 501;
      }

      &::before {
        font-family: ${fonts.fontAwesome};
        font-weight: 100;
        color: ${colors.greyBrown};
      }
    }
    .slick-prev {
      top: 100%;
      left: 57.5%;

      @media (min-width: 768px) {
        top: 40%;
        left: 5rem;
      }

      &::before {
        content: "\f054";
      }
    }

    .slick-next {
      top: 100%;
      left: 37.5%;

      @media (min-width: 768px) {
        top: 60%;
        left: 5rem;
      }

      &::before {
        content: "\f053";
      }
    }

    .slick-track {
      @media (min-width: 768px) {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
      }

      .slick-slide {
        padding: 1.75rem;
        height: 100%;
        min-height: 55rem;

        @media (min-width: 1025px) {
          min-height: 45rem;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }
`

const ComStory = styled.div`
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;

  &:focus {
    outline: none !important;
  }

  .comStory-image {
    flex-grow: auto;
  }

  .comStory-content {
    position: relative;
    padding: 4rem;
    flex-grow: 2;
    background-color: ${colors.grey};

    &::before {
      position: absolute;
      right: 0;
      bottom: -1.5rem;
      left: 0;
      width: 3rem;
      height: 3rem;
      margin: auto;
      background-color: ${colors.grey};
      transform: rotate(45deg);
      content: "";
    }

    span {
      display: block;
      ${fontSizer(5, 7, 76.8, 150, 5)};
      color: ${colors.greyBrown};
      font-family: ${fonts.fontPrimary};
      text-align: center;
      line-height: 0.5;
    }

    p {
      ${B1Black};
      ${fontSizer(1.2, 1.5, 76.8, 150, 1.6)};
      text-align: center;

      &:last-of-type {
        margin: 0;
      }
    }
  }

  .comStory-name {
    flex-grow: auto;
    margin-top: 5rem;
    margin-bottom: 2.5rem;
    text-align: center;

    h3 {
      ${H3Green};
    }
  }
`

const Stories = ({ data, location }) => {
  const stories = data.edges
  return (
    <CommunityStoriesSection>
      <div className="wrapper">
        <Slider {...settings} className="commSlider">
          {stories.length > 0 &&
            stories.map((story, index) => {
              return (
                <ComStory key={index}>
                  <div className="comStory-image">
                    <Img
                      fluid={
                        story.node.acf._coo_cost_featured_image.localFile
                          .childImageSharp.fluid
                      }
                      alt={story.node.acf._coo_cost_featured_image.alt_text}
                    />
                  </div>
                  <div className="comStory-content">
                    <span>&rdquo;</span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: story.node.acf._coo_cost_community_story,
                      }}
                    />
                  </div>
                  <div className="comStory-name">
                    <h3>{story.node.acf._coo_cost_name}</h3>
                  </div>
                </ComStory>
              )
            })}
        </Slider>
      </div>
    </CommunityStoriesSection>
  )
}

export default Stories
