import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/Seo"
import TopQuote from "../components/TestimonialsStories/TopQuote"
import Stories from "../components/TestimonialsStories/Stories"
import Callout from "../components/TestimonialsStories/Callout"

const TestimonialsStories = props => {
  const { seoInfo, topQuote, stories, callOut } = props.data
  const location = props.location.pathname
  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={location}
      />
      <TopQuote data={topQuote} />
      <Stories data={stories} location={location} />
      <Callout data={callOut} />
    </Layout>
  )
}

export const testStoryQuery = graphql`
  query testStoryPage($id: Int!) {
    seoInfo: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    topQuote: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_toqu_title
        _coo_toqu_content
      }
    }

    stories: allWordpressAcfCommunityStories {
      edges {
        node {
          acf {
            _coo_cost_name
            _coo_cost_community_story
            _coo_cost_featured_image {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }

    callOut: wordpressPage(wordpress_id: { eq: $id }) {
      acf {
        _coo_hoca_top_title
        _coo_hoca_top_content
        _coo_hoca_top_btn_text
        _coo_hoca_top_btn_link_two
        _coo_hoca_top_image {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        _coo_hoca_bg_color
      }
    }
  }
`

export default TestimonialsStories
